import React from 'react'
import { graphql } from 'gatsby'
import Seo from 'components/Seo'
import PageTemplate from './template'

const ContentType = (props) => {
  const { data, pageContext, layoutProps } = props
  const { locale } = pageContext
  const { page } = data
  const content = page.frontmatter[locale]
  return (
    <>
      <Seo content={content} locale={locale} />
      <PageTemplate
        pageId={page.id}
        content={content}
        locale={locale}
        layoutProps={layoutProps}
      />
    </>
  )
}

export default ContentType

export const pageQuery = graphql`
  query ($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        en {
          slug
          title
          location
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
          imageMobile {
            childImageSharp {
              gatsbyImageData
            }
          }
          imageText
          postBuilder {
            title
            body
            images {
              image {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          seo {
            title
            description
            keywords
          }
        }
        zh {
          slug
          title
          location
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
          imageMobile {
            childImageSharp {
              gatsbyImageData
            }
          }
          imageText
          postBuilder {
            title
            body
            images {
              image {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          seo {
            title
            description
            keywords
          }
        }
      }
    }
  }
`
