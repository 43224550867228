// extracted by mini-css-extract-plugin
export var arrow = "styles-module--arrow--V5uXS";
export var component = "styles-module--component--HdBWr";
export var desktop = "styles-module--desktop--mY6mp";
export var image = "styles-module--image--mrwU5";
export var loaded = "styles-module--loaded--nWqph";
export var mobile = "styles-module--mobile--sxw8+";
export var scrollDown = "styles-module--scroll-down--z32Sa";
export var text = "styles-module--text--cG99o";
export var textWhite = "styles-module--text-white--sPGtI";
export var video = "styles-module--video--DT6FM";
export var wrap = "styles-module--wrap--uFOcV";