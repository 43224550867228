import React, { useState, useEffect } from 'react'
import HeaderVersionWrap from 'components/HeaderVersionWrap'
import ParallaxWrapBanner from 'components/ParallaxWrapBanner'
import ImageWrap from 'components/ImageWrap'
import * as styles from './styles.module.scss'

const Block = ({ content, locale, layoutProps }) => {
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    setIsLoaded(true)
  }, [])

  const scrollDown = (e) => {
    const block = e.target.closest('.block')
    window.scrollTo({
      top: block.offsetHeight,
      behavior: 'smooth',
    })
  }

  const { pretitle, title, subtitle, image, imageMobile, imageText } = content
  const isLoadedClass = isLoaded ? styles.loaded : ''
  const imageTextClass = imageText === 'white' ? styles.textWhite : ''
  const bgDarkClass = imageText === 'white' ? 'bg-dark' : ''
  const borderColorClass = imageText === 'white' ? 'white' : ''

  return (
    <div
      className={`block ${styles.component} ${isLoadedClass} ${imageTextClass} ${bgDarkClass}`}
    >
      <HeaderVersionWrap
        layoutProps={layoutProps}
        isWhite={imageText === 'white'}
      >
        <ParallaxWrapBanner>
          <div className={`${styles.image} ${styles.desktop}`}>
            <ImageWrap image={image} />
          </div>
          <div className={`${styles.image} ${styles.mobile}`}>
            <ImageWrap image={imageMobile} />
          </div>
        </ParallaxWrapBanner>
        <div className={styles.text}>
          <div className={styles.wrap}>
            {pretitle && <h5>{pretitle}</h5>}
            <div className={`border ${borderColorClass} smaller`} />
            <h1>{title}</h1>
            <h5>{subtitle}</h5>
          </div>
        </div>
        <div className={styles.scrollDown} onClick={scrollDown}>
          <div className={styles.arrow} />
        </div>
      </HeaderVersionWrap>
    </div>
  )
}

export default Block
