import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import PaginationBlocks from 'blocks/PaginationBlocks/template'
import { getPaginationItems } from 'js/utils'

const Block = ({ content, data, locale }) => {
  const strings = {
    en: {
      previous: 'Previous',
      next: 'Next',
    },
    zh: {
      previous: '前一个',
      next: '下一个',
    },
  }
  const tr = strings[locale]

  const { project } = content
  const projects = data?.projects?.nodes
  const projectsLocale = projects?.map((item) => item.frontmatter[locale])
  const paginationItems = getPaginationItems(project, projectsLocale)
  const paginationBlocksContent = {
    prev: {
      pretitle: tr.previous,
      title: paginationItems.prevItem.title,
      subtitle: paginationItems.prevItem.location,
      image: paginationItems.prevItem.image,
      imageText: paginationItems.prevItem.imageText,
      link: `/projects/${paginationItems.prevItem.slug}/`,
    },
    next: {
      pretitle: tr.next,
      title: paginationItems.nextItem.title,
      subtitle: paginationItems.nextItem.location,
      image: paginationItems.nextItem.image,
      imageText: paginationItems.nextItem.imageText,
      link: `/projects/${paginationItems.nextItem.slug}/`,
    },
  }

  return (
    <div>
      <PaginationBlocks content={paginationBlocksContent} />
    </div>
  )
}

export default function Data({ content, locale }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          projects: allMarkdownRemark(
            filter: { frontmatter: { en: { templateKey: { eq: "project" } } } }
            sort: { fields: frontmatter___en___order, order: ASC }
          ) {
            nodes {
              frontmatter {
                en {
                  order
                  slug
                  title
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                  imageText
                  location
                }
                zh {
                  order
                  slug
                  title
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                  imageText
                  location
                }
              }
            }
          }
        }
      `}
      render={(data) => <Block content={content} data={data} locale={locale} />}
    />
  )
}
