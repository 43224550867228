export const scrollToContact = () => {
  const block = document.querySelector('.BlockContact')
  const nav = document.querySelector('.nav')
  const blockY = block.offsetTop + nav.offsetHeight
  window.scrollTo({
    top: blockY,
    behavior: 'smooth',
  })
}

export const makeYellow = (filename) => {
  const arr = filename.split('.')
  const newFilename = `${arr[0]}_yellow.${arr[1]}`
  return newFilename
}
export const getNextItems = (allItems, thisItemSlug, length) => {
  const thisIndex = allItems.findIndex((item) => item.slug === thisItemSlug)
  const allItems2 = allItems.concat(allItems)
  let allItemsSlice = allItems2.slice(thisIndex + 1, thisIndex + 1 + length)
  allItemsSlice = allItemsSlice.filter((item) => item.slug !== thisItemSlug)
  const allItemsUnique = [...new Set(allItemsSlice)]
  return allItemsUnique
}
export const getRelatedChild = (parentField, allNodes, locale) => {
  if (parentField && typeof parentField === 'string') {
    const result = allNodes.find(
      (item) => item.frontmatter[locale].slug === parentField
    )
    return result.frontmatter[locale]
  }
}
export const isObjectEmpty = (obj) => {
  for (var key in obj) {
    if (obj[key] !== null && obj[key] != '') return false
  }
  return true
}

export const formatDate = (dateIn, lan) => {
  let formatDate = ''
  if (lan === 'en') {
    const options = {
      month: 'short',
      year: 'numeric',
      day: 'numeric',
    }
    formatDate = dateIn.toLocaleDateString('en-US', options)
  }
  if (lan === 'zh') {
    const year = dateIn.getYear() + 1900
    const month = dateIn.getMonth() + 1
    const date = dateIn.getDate()
    formatDate = `${year}-${month}-${date}`
  }

  return formatDate
}

export const getPaginationItems = (thisItem, allItems) => {
  let thisItemIndex
  for (let i = 0; i < allItems.length; i++) {
    if (allItems[i].slug === thisItem.slug) {
      thisItemIndex = i
    }
  }
  const prevItemIndex =
    thisItemIndex - 1 === -1 ? allItems.length - 1 : thisItemIndex - 1
  const prevItem = allItems[prevItemIndex]
  const nextItemIndex =
    thisItemIndex + 1 === allItems.length ? 0 : thisItemIndex + 1
  const nextItem = allItems[nextItemIndex]

  return { prevItem, nextItem }
}
