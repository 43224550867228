import React, { useState } from 'react'
import Inview from 'components/Inview'
import ImageWrap from 'components/ImageWrap'
import LinkWrap from 'components/LinkWrap'
import * as styles from './styles.module.scss'

const Block = ({ content }) => {
  return (
    <div className={`${styles.component}`}>
      <div className={styles.itemsWrap}>
        <Item content={content.prev} direction='prev' />
        <Item content={content.next} direction='next' />
      </div>
    </div>
  )
}

export default Block

const Item = ({ content, direction }) => {
  const { pretitle, title, subtitle, image, imageText, link } = content
  const directionClass = direction === 'prev' ? styles.prev : styles.next
  const imageTextClass = imageText === 'white' ? styles.textWhite : ''
  return (
    <LinkWrap
      className={`${styles.item} ${directionClass} ${imageTextClass} zoom-parent`}
      to={link}
    >
      <Inview className='fade-in'>
        <div className={`${styles.image} zoom`}>
          <ImageWrap image={image} />
        </div>
        <div className='container'>
          <div className={styles.text}>
            <div className={styles.pretitle}>
              <h5>{pretitle}</h5>
              <div className={styles.line} />
            </div>
            <h3 className={styles.title}>{title}</h3>
            <h6>{subtitle}</h6>
          </div>
        </div>
      </Inview>
    </LinkWrap>
  )
}
