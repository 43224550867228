import React from 'react'
import BannerImage from 'blocks/BannerImage/template'
import PostBuilder from 'blocks/PostBuilder/template'
import More from 'blocks/More/template'
import ProjectsPagination from 'blocks/_dynamic/ProjectsPagination/template'
import Footer from 'components/Footer'

const ContentType = ({ pageId, content, locale, layoutProps }) => {
  const strings = {
    en: {
      projects: 'Projects',
      moreProjects: 'More Projects',
    },
    zh: {
      projects: '项目',
      moreProjects: '更多项目',
    },
  }
  const tr = strings[locale]

  const pageContent = {
    bannerImage: {
      // pretitle: tr.projects,
      title: content.title,
      image: content.image,
      imageMobile: content.imageMobile,
      imageText: content.imageText,
      subtitle: content.location,
    },
    postBuilder: {
      sections: content.postBuilder,
    },
    more: {
      subtitle: tr.moreProjects,
      isNoCollapse: true,
    },
    projectsPagination: {
      project: content,
    },
  }
  return (
    <div className='page'>
      <BannerImage
        content={pageContent.bannerImage}
        layoutProps={layoutProps}
      />
      {pageContent.postBuilder.sections && (
        <PostBuilder content={pageContent.postBuilder} />
      )}
      <More content={pageContent.more} />
      <ProjectsPagination
        content={pageContent.projectsPagination}
        locale={locale}
      />
      <Footer locale={locale} />
    </div>
  )
}

export default ContentType
