// extracted by mini-css-extract-plugin
export var component = "styles-module--component--RzTMn";
export var image = "styles-module--image--hN2fN";
export var item = "styles-module--item--3MNPR";
export var itemsWrap = "styles-module--items-wrap--xcGSk";
export var line = "styles-module--line--aLzWK";
export var next = "styles-module--next--FoX7J";
export var pretitle = "styles-module--pretitle--4O5Mm";
export var prev = "styles-module--prev--ffqxs";
export var text = "styles-module--text--cjLr1";
export var textWhite = "styles-module--text-white--0Mmgo";
export var title = "styles-module--title--rT4xC";