import React from 'react'
import ImageWrap from 'components/ImageWrap'
import Inview from 'components/Inview'
import MarkdownWrap from 'components/MarkdownWrap'
import * as styles from './styles.module.scss'

const Block = ({ content }) => {
  const { sections } = content
  return (
    <div className='block pad-v-standard'>
      <div className='container'>
        {sections.map((item, i) => (
          <Section content={item} key={i} />
        ))}
      </div>
    </div>
  )
}

export default Block

const Section = ({ content }) => {
  const { title, body, images } = content
  return (
    <div className={styles.section}>
      <Inview className='grid-24 fade-in up'>
        <h5 className={`${styles.title} bold`}>{title}</h5>
        <div className={`body justified ${styles.body}`}>
          <MarkdownWrap body={body} />
        </div>
      </Inview>
      <div className={styles.images}>
        {images?.map((item, i) => (
          <Inview className={`${styles.image} grid-24 fade-in up`} key={i}>
            <ImageWrap image={item.image} />
          </Inview>
        ))}
      </div>
    </div>
  )
}
